// Checkbox
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
    border-color: $base-light-blue;
}

.parent-checbox {
    margin-bottom: toRem(20);


    p-checkbox {
        margin-bottom: toRem(10);
        display: flex;
    }

    .p-checkbox-label {
        font-weight: $font-semibold;
        margin-left: toRem(10);
    }

    .child-checbox {
        margin-left: toRem(16);

        .p-checkbox-label {
            font-weight: $fontWeight;

        }
    }
}

.p-checkbox {
    width: toRem(16);
    height: toRem(16);

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: $base-light-blue !important;
        background: $base-light-blue !important;
        box-shadow: none;
    }

    .p-checkbox-box {
        border: 1px solid $base-blue;
        background: $base-white;
        border-radius: toRem(4);
        font-size: toRem(12);
        min-width: toRem(18);
        min-height: toRem(18);
        max-width: toRem(18);
        max-height: toRem(18);

        &.p-highlight {
            border-color: $base-light-blue !important;
            background: $base-light-blue !important;
            box-shadow: none;
        }

        .p-checkbox-icon {
            width: toRem(12);
            height: toRem(12);
        }
    }
}

.p-checkbox-label {
    margin-left: toRem(10);
    margin-bottom: 0;
}