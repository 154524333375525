// ToolTips
.p-overlaypanel,
.p-menu-overlay {
    max-width: toRem(450);
    border-radius: toRem(8);
    background: $base-white;
    box-shadow: 0px 6px 10px 0px rgba(42, 41, 74, 0.12);
    color: $textColor;
    font-size: toRem(12) !important;

    .p-overlaypanel-content {
        line-height: 140%;
        padding: toRem(16);

        ul {
            li {
                margin-bottom: toRem(10);
                cursor: pointer;
                display: block;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @include media(xs, sm) {
            padding: 0 toRem(16) toRem(16) toRem(16);
        }
    }

    &::after,
    &::before {
        display: none;
    }
}

// Tool Tip
.p-tooltip {
    &.p-tooltip-bottom .p-tooltip-arrow {
        border-bottom-color: $base-white;
    }

    &.p-tooltip-left .p-tooltip-arrow {
        border-left-color: $base-white;
    }

    &.p-tooltip-right .p-tooltip-arrow {
        border-right-color: $base-white;
    }

    &.p-tooltip-top .p-tooltip-arrow {
        border-top-color: $base-white;
    }

    .p-tooltip-text {
        font-size: toRem(14);
        padding: toRem(12) toRem(16);
        color: $textColor;
        border-radius: toRem(8);
        background: $base-white;
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.15);
        line-height: 150%;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.45);
    display: none;
    z-index: 1001;

    @include media(xs, sm, md, lg) {
        display: flex;
    }
}