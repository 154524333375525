// NG Select Styles 

.ng-select-container {
    border-radius: toRem(6) !important;
    border: 1px solid $border-color !important;
    background: $base-white !important;
    min-height: initial !important;
    height: initial !important;

    .ng-value-container {

        min-height: toRem(45) !important;

        .ng-placeholder {
            color: $textColor;
            font-size: toRem(12) !important;

            &:empty:not(:focus):before {
                content: attr(placeholder)
            }

            &:empty:before {
                content: attr(placeholder)
            }
        }

        .ng-input {
            // height: -webkit-fill-available !important;
            top: 0 !important;
            position: relative !important;

            input {
                //  height: -webkit-fill-available !important;
                padding: toRem(11) toRem(20);
                color: $textColor;
                font-size: toRem(14) !important;
            }
        }
    }


    &:hover,
    &:focus,
    &:active,
    &:enabled:hover {
        border-color: $base-light-blue !important;
        box-shadow: none !important;
    }
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: $base-light-blue !important;
    box-shadow: none !important;
}

.ng-dropdown-panel {
    box-shadow: 0px 6px 10px 0px #0000001A;

    border: 1px solid $border-color;

    .scroll-host {
        padding: toRem(16);

        @include scrollbars();
    }

    .ng-dropdown-panel-items {
        .ng-option {
            //margin-bottom: toRem(10);
            color: $textColor !important;
            font-size: toRem(14) !important;
            padding: toRem(16);
        }
    }
}