.w-25rem {
  .p-sidebar-content {
    padding: toRem(30) toRem(20) !important;
    // min-height: calc(100vh - toRem(30))!important;
    max-height: calc(100vh - toRem(30)) !important;
    position: relative;
    //     z-index: 1005 !important;
    @include scrollbars();
    overflow: auto;
  }
}
.p-sidebar {
  background: $base-white;
  box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.2);
  // z-index: 1005 !important;
  @include scrollbars();

  .p-sidebar-header,
  .p-sidebar-footer {
    display: none;
  }

  .p-sidebar-content {
    //padding: toRem(30) toRem(20) !important;
    min-height: 10vh;
    position: relative;
    //     z-index: 1005 !important;
    @include scrollbars();
    overflow: auto;
  }

  .tags-sidebar {
    h3 {
      color: $textColor;
      font-size: toRem(21);
      font-weight: $font-medium;
    }
  }
}

.p-sidebar-footers {
  margin: toRem(20) toRem(0);
  padding: toRem(25) toRem(0);
  border-top: 1px solid $border-color;
  border-bottom-right-radius: toRem(10);
  border-bottom-left-radius: toRem(10);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
