//Custom Colors Variables
$header-bg: var(--text-only, linear-gradient(0deg, rgba(0, 36, 56, 0.50) 0%, rgba(0, 36, 56, 0.50) 100%), #002438);
$scrollbar-bg: rgba(0, 36, 56, .2);
$scrollbar-thumb: #095A70;
$base-bg: #F5FAFF;
$border-color: rgba(0, 36, 56, .2);
$base-light-blue: #47D8BF;
$base-blue: #095A70;
$base-white: #fff;
$textColor: #002438;
$base-orange: #FF6C39;

$base-yellow: #F89D16;
$base-red: #F61515;
$base-grey: grey;
$base-cyan: #2ED0B4;

$avatar-border: #F9FAFE;
$switch-bg:#BFBFBF;

// Custom Font Variables. 
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

// Function to convert PX to Rem 
@function toRem($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
}