@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?986s4g');
  src:  url('fonts/icomoon.eot?986s4g#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?986s4g') format('truetype'),
    url('fonts/icomoon.woff?986s4g') format('woff'),
    url('fonts/icomoon.svg?986s4g#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-drawer .path1:before {
  content: "\e957";
  color: rgb(71, 216, 191);
}
.icon-drawer .path2:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cross:before {
  content: "\e948";
}
.icon-edit1:before {
  content: "\e949";
}
.icon-plus:before {
  content: "\e94a";
}
.icon-video:before {
  content: "\e94b";
}
.icon-info:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e901";
}
.icon-calender:before {
  content: "\e906";
}
.icon-remove-priority:before {
  content: "\e907";
}
.icon-copy:before {
  content: "\e908";
}
.icon-cross-priority:before {
  content: "\e909";
}
.icon-add-priority:before {
  content: "\e90a";
}
.icon-status:before {
  content: "\e90b";
}
.icon-save-search-bar:before {
  content: "\e90c";
}
.icon-edit:before {
  content: "\e90d";
}
.icon-grid:before {
  content: "\e90e";
}
.icon-save-search:before {
  content: "\e90f";
}
.icon-exit:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e912";
}
.icon-remove-coverage:before {
  content: "\e913";
}
.icon-add-coverage:before {
  content: "\e914";
}
.icon-notification:before {
  content: "\e915";
}
.icon-tag:before {
  content: "\e916";
}
.icon-delete:before {
  content: "\e917";
}
.icon-in-person:before {
  content: "\e91b";
}
.icon-thumbs-up:before {
  content: "\e91c";
}
.icon-mail:before {
  content: "\e91d";
}
.icon-star:before {
  content: "\e91e";
}
.icon-enrich:before {
  content: "\e91f";
}
.icon-circle-left:before {
  content: "\e920";
}
.icon-circle-right:before {
  content: "\e921";
}
.icon-graph-up:before {
  content: "\e922";
}
.icon-graph-down:before {
  content: "\e923";
}
.icon-upload:before {
  content: "\e924";
}
.icon-download:before {
  content: "\e925";
}
.icon-arrow-right:before {
  content: "\e92c";
}
.icon-arrow-left:before {
  content: "\e92d";
}
.icon-arrow-up:before {
  content: "\e92e";
}
.icon-arrow-down:before {
  content: "\e92f";
}
