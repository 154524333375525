* {
    scroll-behavior: smooth;
}

html {
    font-size: toRem(16);
    scroll-behavior: smooth;
    background: $base-bg;
    font-weight: $fontWeight;

    @include media(extension) {
        font-size: toRem(16);
    }

    @include media(sm) {
        font-size: toRem(10);
    }

    @include media(md) {
        font-size: toRem(12);
    }

    @include media(lg) {
        font-size: toRem(11);
    }

    @include media(xl) {
        font-size: toRem(14);
    }

    @include media(xxl) {
        font-size: toRem(15);
    }

    @include media(xxxl) {
        font-size: $fontSize;
    }
}

body {
    font-family: $fontFamily;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: $textColor;
    font-size: inherit;
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    @include scrollbars;

    @include media(md, lg, xl, xxl, xxxl) {
        overflow: hidden;
        height: 100vh;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontFamily;
    font-weight: $font-bold;
    line-height: normal;
}

a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

strong {
    font-weight: $font-bold;
}

.fluid-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

// Textarea 
.text-area {
    ng-component>div {
        display: none !important;
    }
}