.p-component {
  font-family: $fontFamily !important;
  font-size: inherit !important;
  font-weight: $fontWeight;
}

.p-tabview {
  .p-tabview-nav-container {
    .p-tabview-nav-content {
    }

    ul.p-tabview-nav {
      background: transparent;

      li {
        margin-right: toRem(30);

        @include media(xs, sm) {
          margin-right: 9px;
        }

        .p-tabview-nav-link {
          color: $textColor;
          font-size: toRem(18);
          padding: 0 0px toRem(24) 0;
          background: transparent !important;
          box-shadow: none !important;
          outline: none !important;
          font-weight: $fontWeight;
          opacity: 0.5;

          &:hover,
          &:focus,
          &:active {
            color: $base-orange !important;
            border-color: $base-orange !important;
            background: transparent !important;
            text-decoration: none !important;
            opacity: 1;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &.p-highlight .p-tabview-nav-link {
          color: $base-orange;
          border-color: $base-orange;
          font-weight: $font-semibold;
          opacity: 1;
          border-bottom-width: toRem(4);
        }

        &.p-tabview-ink-bar {
          background-color: transparent;
        }
      }
    }
  }

  .p-tabview-panels {
    background-color: transparent;
    padding: 0;
    color: $textColor;
  }

  &.details-tabs {
    .p-tabview-nav-container {
      .p-tabview-nav-content {
      }

      ul.p-tabview-nav {
        li {
          margin-right: toRem(30);

          .p-tabview-nav-link {
            font-size: toRem(16);
            padding: 0 0px toRem(12) 0;
          }
        }
      }
    }

    &.pannel {
      .p-tabview-panels {
        padding: toRem(30) 0;
      }
    }
  }

  .p-datatable {
    .p-datatable-wrapper {
      border-radius: toRem(8);
      border: 1px solid $border-color;
      background: $base-white;
      //  box-shadow: 0px 6px 16px 0px rgba(69, 82, 117, 0.10);
      margin-bottom: 21px;
      @include media(md, lg) {

      }

      @include media(xl, xxl, xxxl) {

      }
    }

    .p-datatable-table {
      width: 100%;

      .p-datatable-thead {
        background-color: $base-bg;

        .fa-sort-up,
        .fa-sort-down {
          color: $base-orange;
        }

        tr {
          //  border-bottom: 1px solid $border-color;
          &::after {
            content: "";
            border-bottom: 1px solid $border-color;
            position: relative;
            width: 100%;
            height: 1px;
            margin: -1px 0 0 0;
            bottom: -54px;
          }

          th {
            border: none;
            border-bottom: 1px solid $border-color;
            color: $textColor;
            font-size: toRem(14);
            font-weight: 700;
            line-height: normal;
            padding: toRem(16);
            min-height: toRem(56);
          }
        }
      }

      .p-datatable-tbody {
        tr {
          //  border-bottom: 1px solid $border-color;
          &::after {
            content: "";
            border-bottom: 1px solid $border-color;
            position: relative;
            width: 100%;
            height: 1px;
            margin: -1px 0 0 0;
          }

          td {
            border: none;
            border-bottom: 1px solid $border-color;
            padding: toRem(16);
            vertical-align: middle;
            line-height: 150%;
            color: $textColor;
            font-size: toRem(14);

            .name-wrap {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }

            .social-wrap {
              .social-link {
                margin-right: 10px;

                &.social-link,
                a {
                  display: flex;
                  align-items: center;
                  line-height: normal;
                }
              }

              img {
                max-width: 21px;
              }

              @include media(xs, sm){
                display: none !important;
              }
            }

            .company-info {
              margin-left: 10px;

              .company-link {
                color: $textColor;
                font-size: toRem(16);
                font-weight: $font-semibold;
                line-height: normal;
                display: block;
                opacity: 0.8;

                &:hover {
                  text-decoration: none;
                }
              }

              .company-address {
                color: $base-blue;
                font-size: toRem(14);
              }
            }
          }
        }
      }
    }

    .p-datatable-footer {
      background: transparent;
      padding: 0;
      border: none;

      @include media(xs, sm) {
        display: none;
      }
    }

    .p-paginator {
      background-color: $base-bg;
      border: none;
      justify-content: flex-end;
      padding: toRem(20) 0 toRem(0);
      font-weight: $fontWeight;
      display: flex;
      align-items: center;
      margin-top: -21px;
      margin-bottom: 24px;  

      .p-paginator-current {
        color: $textColor;
        font-size: toRem(14);
      }

      .p-paginator-pages .p-paginator-page,
      .p-paginator-first,
      .p-paginator-prev,
      .p-paginator-next,
      .p-paginator-last {
        border-radius: toRem(6);
        border: 1px solid $border-color;
        background: $base-white;
        color: $textColor;
        text-align: center;
        font-size: toRem(14);
        height: toRem(32);
        min-width: toRem(32);
        margin: 0 toRem(4);

        &.p-highlight {
          background-color: $base-light-blue;
          color: $base-white;
          border-color: $base-light-blue;
          box-shadow: none;
        }
      }

      .p-dropdown {
        max-height: toRem(32);
        padding: 0;

        .p-inputtext {
          padding: toRem(8) toRem(14);
        }

        .p-dropdown-trigger {
          background: transparent;
          color: $textColor;
          //  width: fit-content;
          background: $base-white;

          .pi {
            padding-right: toRem(14);
            font-size: toRem(10);
          }
        }
      }
    }
  }

  .p-datatable-scrollable .p-datatable-wrapper {
    border-radius: toRem(8);

    @include media(xs, sm) {
      display: none;
    }
    overflow-x: hidden;
    @include scrollbars();
  }

  .company-table,
  .pipeline-table,
  .priority-table,
  .elevation-table,
  .data-quality {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:first-child {
          min-width: toRem(350);
          max-width: toRem(350);

          .th-checkbox {
            position: relative;
            top: -3px;
          }
        }

        &:nth-child(2n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(3n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(4n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(5n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(6n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(7n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(8n) {
          min-width: toRem(130);
          max-width: toRem(130);
        }

        &:nth-child(9n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(10n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(11n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }
      }
    }
  }

  .interactions-table,
  .pipeline-table,
  .priority-table,
  .elevation-table,
  .data-quality {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:first-child {
          min-width: toRem(350);
          max-width: toRem(350);

          .th-checkbox {
            position: relative;
            top: -3px;
          }
        }

        &:nth-child(2n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(3n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(4n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(5n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(6n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(7n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(8n) {
          min-width: toRem(130);
          max-width: toRem(130);
        }

        &:nth-child(9n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(10n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(11n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }
      }
    }
  }

  .elevation-table {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:nth-child(2n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(3n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(4n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(5n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(6n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(7n) {
          min-width: toRem(160);
          max-width: toRem(160);
        }

        &:nth-child(8n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }

        &:nth-child(9n) {
          min-width: toRem(320);
          max-width: toRem(320);
        }
      }
    }
  }

  .voting-table {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:first-child {
          min-width: toRem(350);
          max-width: toRem(350);

          .th-checkbox {
            position: relative;
            top: -3px;
          }
        }

        &:nth-child(2n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(3n) {
          min-width: toRem(180);
          max-width: toRem(180);
        }

        &:nth-child(4n) {
          min-width: toRem(180);
          max-width: toRem(180);
        }

        &:nth-child(5n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(6n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }

        &:nth-child(7n) {
          min-width: toRem(190);
          max-width: toRem(190);
          justify-content: flex-end;
        }

        &:nth-child(8n) {
          min-width: toRem(350);
          max-width: toRem(350);
        }

        &:nth-child(9n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(10n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }

        &:nth-child(11n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }
      }
    }
  }

  .pipeline-table {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:first-child {
          min-width: toRem(250);
          max-width: toRem(250);

          .th-checkbox {
            position: relative;
            top: -3px;
          }
        }
        &:nth-child(2n) {
          min-width: toRem(100);
          max-width: toRem(100);
        }

        &:nth-child(3n) {
          min-width: toRem(130);
          max-width: toRem(130);
        }

        &:nth-child(4n) {
          min-width: toRem(250);
          max-width: toRem(250);
        }

        &:nth-child(5n) {
          min-width: toRem(130);
          max-width: toRem(130);
        }

        &:nth-child(6n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(7n) {
          min-width: toRem(180);
          max-width: toRem(180);
        }

        &:nth-child(8n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(9n) {
          min-width: toRem(180);
          max-width: toRem(180);
        }

        &:nth-child(10n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(11n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }
      }
    }
  }

  .priority-table {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:nth-child(2n) {
          min-width: toRem(100);
          max-width: toRem(100);
        }

        &:nth-child(3n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(4n) {
          min-width: toRem(100);
          max-width: toRem(100);
        }

        &:nth-child(5n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(6n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }

        &:nth-child(7n) {
          min-width: toRem(130);
          max-width: toRem(130);
        }

        &:nth-child(8n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(9n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(10n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(11n) {
          min-width: toRem(180);
          max-width: toRem(180);
        }
      }
    }
  }

  .data-quality {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:nth-child(2n) {
          min-width: toRem(100);
          max-width: toRem(100);
        }

        &:nth-child(3n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(4n) {
          min-width: toRem(250);
          max-width: toRem(250);
        }

        &:nth-child(5n) {
          min-width: toRem(100);
          max-width: toRem(100);
        }

        &:nth-child(6n) {
          min-width: toRem(250);
          max-width: toRem(250);
        }

        &:nth-child(7n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(8n) {
          min-width: toRem(200);
          max-width: toRem(200);
        }

        &:nth-child(9n) {
          min-width: toRem(250);
          max-width: toRem(250);
        }

        &:nth-child(10n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(11n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }
      }
    }
  }

  .saved-search {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:first-child {
          min-width: 40%;
          max-width: 40%;

          .th-checkbox {
            position: relative;
            top: -3px;
          }
        }

        &:nth-child(2n) {
          min-width: 30%;
          max-width: 30%;
        }

        &:nth-child(3n) {
          min-width: 30%;
          max-width: 30%;
        }
      }

      .filter-count-wrapper {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filter-count-icons {
          width: 140px;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }
    }
  }

  .user-table {
    .p-datatable-table {
      .p-datatable-thead tr > th,
      .p-datatable-tbody tr > td {
        &:nth-child(1n) {
          min-width: toRem(130);
          max-width: toRem(130);
        }

        &:nth-child(2n) {
          min-width: toRem(100);
          max-width: toRem(100);
        }

        &:nth-child(3n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(4n) {
          min-width: toRem(100);
          max-width: toRem(100);
        }

        &:nth-child(5n) {
          min-width: toRem(130);
          max-width: toRem(130);
        }

        &:nth-child(6n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(7n) {
          min-width: toRem(180);
          max-width: toRem(180);
        }

        &:nth-child(8n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }

        &:nth-child(9n) {
          min-width: toRem(180);
          max-width: toRem(180);
        }

        &:nth-child(10n) {
          min-width: toRem(120);
          max-width: toRem(120);
        }

        &:nth-child(11n) {
          min-width: toRem(150);
          max-width: toRem(150);
        }
      }
    }
  }
}
