.grid-no-tb-gutter {
    margin-top: 0;
    margin-bottom: 0;
}

.grid.p-30 {
    margin: 0 toRem(15);

    &:only-child {
        margin: 0;
    }
}


.grid.p-30>.col,
.grid.p-30>[class*=col-] {
    padding: 0 toRem(15);

    &:last-child {
        padding-right: 0;
    }

    &:only-child {
        padding: 0;
    }
}

.grid.p-20 {
    margin: 0 toRem(15);

    &:only-child {
        margin: 0;
    }
}


.grid.p-20>.col,
.grid.p-20>[class*=col-] {
    padding: 0 toRem(10);

    &:last-child {
        padding-right: 0;
    }

    &:only-child {
        padding: 0;
    }
}

.grid-no-tb-gutter>.col,
.grid-no-tb-gutter>[class*=col-] {
    padding-top: 0;
    padding-bottom: 0;
}