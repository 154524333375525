//Partials
@import "./helpers/_variables";
@import "./helpers/_theme";
@import "./helpers/mixins";
@import "./helpers/base";
@import "./helpers/_utils";
@import "./helpers/_grid";

// Primeng Overrides
@import "./prime-ng/buttons.scss";
@import "./prime-ng/accordian.scss";
@import "./prime-ng/avatar.scss";
@import "./prime-ng/checkboxes.scss";
@import "./prime-ng/ng-select.scss";
@import "./prime-ng/radio.scss";
@import "./prime-ng/sidebar.scss";
@import "./prime-ng/switch.scss";
@import "./prime-ng/table.scss";
@import "./prime-ng/tags.scss";
@import "./prime-ng/textboxes.scss";
@import "./prime-ng/overlay.scss";

//Components
@import "./components/filters.scss";

header {
  ::-webkit-input-placeholder {
    color: $base-white;
  }

  ::-moz-placeholder {
    color: $base-white;
  }

  :-ms-input-placeholder {
    color: $base-white;
  }

  :-moz-placeholder {
    color: $base-white;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: toRem(12);
  color: $textColor;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: toRem(12);
  color: $textColor;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: toRem(12);
  color: $textColor;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: toRem(12);
  color: $textColor;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.grid-selected-row {
  opacity: 0.5;
}

.sec-head {
  color: $textColor;
  font-size: toRem(21);
  font-weight: 500;
}

.hr {
  margin: 0;
  border: none;
  border-bottom: 1px solid;
  border-color: $border-color;
}

.ellipse-icon {
  font-size: toRem(22);

  @include media(xl, xxl, xxxl) {
    font-size: toRem(18);
  }
}

.clr-blue {
  color: $base-blue;
}

.clr-orange {
  color: $base-orange;
}

.text-red {
  color: $base-red;
}

.text-blue {
  color: $base-red;
}

.text-orange {
  color: $base-orange;
}

.text-green {
  color: $base-light-blue;
}

.lbl-sec {
  color: $base-light-blue;
  font-size: toRem(14);
  font-weight: $font-medium;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
  margin: toRem(8) toRem(15);
}

.tab-extra {
  position: absolute;
  top: 10px;
  right: 0;

  @include media(xs, sm){
    display: none !important;
  }

  .extra-count {
    color: $base-blue;
    font-size: toRem(26);
    font-weight: $font-semibold;
    line-height: toRem(34);

    span {
      position: sticky;
      z-index: 999;
    }
  }

  .extra-label {
    color: $base-blue;
    font-size: toRem(14);
    display: block;
    text-align: right;
    position: sticky;
    z-index: 999;
  }
}

.w-100 {
  width: 100%;
  display: block;

  .p-dropdown {
    width: 100%;
  }
}

.right-cut {
  border-radius: toRem(6) 0 0 toRem(6) !important;
}

.left-cut {
  border-radius: 0 toRem(6) toRem(6) 0 !important;

  .p-dropdown {
    border-radius: 0 toRem(6) toRem(6) 0 !important;
    border-left: none !important;
  }
}

.hr-90 {
  border: none;
  border-bottom: 1px solid $border-color;
  height: 1px;
  margin-bottom: 20px;
}

.info-icon {
  color: $base-blue;
  font-size: toRem(18);
}

.no-padd {
  padding: 0;
}

.action-plus {
  width: toRem(30);
  height: toRem(30);
  font-size: toRem(18);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $textColor;
  border-radius: toRem(6);
  margin-top: toRem(20);
  position: relative;
  cursor: pointer;

  &.left-icon {
    margin-left: 41px;
  }

  &.secondary-icon {
  }

  &::after {
    content: "";
    border-left: 1px solid $border-color;
    top: toRem(-20);
    position: absolute;
    height: toRem(20);
  }
}

.sticky-tab {
  .p-tabview-nav-container {
    position: sticky;
    top: toRem(75);
    z-index: 100;
    background-color: $base-bg;
    padding-top: toRem(32);
  }

  @include media(xs, sm) {
    .p-tabview-nav-container {
      top: toRem(0) !important;
      // background-color: transparent;
    }
  }
}

.sticky {
  position: sticky;
  top: toRem(75);
}

.lbl-clear-filter {
  color: $base-orange;
  font-size: toRem(12);
  font-weight: $font-semibold;
  line-height: normal;
  text-decoration-line: underline;
  margin-left: toRem(10);
  margin-top: toRem(10);
  cursor: pointer;
}

.p-datatable-flex-scrollable .p-datatable-wrapper {
  flex-basis: auto !important;
  height: 0 !important;
}

label {
  font-size: toRem(14);
  font-weight: $font-medium;
  line-height: toRem(17);
  text-align: left;
  margin-bottom: toRem(8);

  .w-full {
    width: 100%;
    display: block;
  }

  &.inline-label {
    margin-bottom: 0;
  }

  &.large {
    font-size: toRem(18);
    font-weight: $font-semibold;
  }
}

// Submenu Stylings
.submenu-wrapper {
  padding: toRem(18) 0 toRem(18);
  background: $base-bg;

  @include media(xs, sm){
    display: none !important;
  }

  .sub-dropdown {
    min-width: toRem(220);
    max-width: toRem(220);
  }

  .card-container {
    max-height: toRem(40);
    overflow: hidden;
  }
}

.ct-1 {
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
}

.show-icon {
  .pi-pencil,
  .pi-calendar {
    display: none;
  }

  &:hover {
    .pi-pencil,
    .pi-calendar {
      display: inline;
    }
  }
}

// PIC Styles
.pic-wrapper {
  padding: toRem(20) 0;

  .total-pic {
    color: $base-blue;
    font-size: toRem(16);
    font-weight: $font-medium;
    line-height: 160%;

    strong {
      font-size: toRem(26);
    }
  }
}

.truncate-words .p-avatar-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-words .p-avatar-text::after {
  content: "\00a0\00a0";
}

// Contact List
.contact-list {
  border-radius: toRem(6);
  background: $base-white;
  box-shadow: 0px 5px 5px 0px rgba(32, 52, 104, 0.06);
  padding: toRem(15) toRem(20);
  margin-bottom: toRem(10);

  .p-avatar a {
    color: $base-white;
    font-size: toRem(14);
  }

  .contact-name {
    color: $base-blue;
    font-size: toRem(16);
    font-weight: $font-semibold;
    line-height: 150%;
  }

  .contact-position {
    color: $base-blue;
    font-size: toRem(14);
    margin-top: toRem(5);
    display: block;
    line-height: 150%;
  }
}

.company-link {
  color: $textColor;
  font-size: toRem(16);
  font-weight: $font-medium;
  line-height: normal;
  display: block;
  opacity: 0.8;

  &:hover {
    text-decoration: none;
  }
}

.mx-w-430 {
  max-width: 430px;
}

.modal-content-msg {
  color: $textColor;
  font-size: toRem(14);
  line-height: 150%;

  &.top-space {
    margin-top: toRem(6);
  }
}

.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.45);
  min-height: auto;
  .p-dialog {
    background: $base-white;
    border-radius: toRem(10);

    // overflow: initial;
    max-width: toRem(980);
    min-width: toRem(420);
    // overflow: auto;
    // @include scrollbars();
    // max-height: calc(100vh - 250px);

    @include extension {
      min-width: calc(100% - 80px);
      max-width: 90%;
    }

    .p-dialog-header {
      padding: toRem(25) toRem(40) toRem(30);
      border-top-right-radius: toRem(10);
      border-top-left-radius: toRem(10);

      .p-dialog-title {
        color: $textColor;
        font-size: toRem(21);
        font-weight: $font-medium;
        line-height: 155%;
      }

      &.bdm-btm {
        margin: toRem(0) toRem(40) toRem(20);
        padding: toRem(30) toRem(0) toRem(20);
        border-bottom: 1px solid $border-color;
      }
    }

    .p-dialog-header-icons {
      display: none;
    }

    .p-dialog-content {
      padding: toRem(0) toRem(40) toRem(25);
      color: $textColor;
      overflow-y: initial;
      @include scrollbars();

      &.header-less {
        padding: 0;
        border-top-right-radius: toRem(10);
        border-top-left-radius: toRem(10);

        &.p-dialog-content {
          max-height: initial;
        }
      }

      &.scrollable {
        max-height: calc(100vh - 250px);
        max-height: -webkit-calc(100vh - toRem(250px));
        max-height: -moz-calc(100vh - toRem(250px));
        overflow: auto;

        @include scrollbars();
      }

      &.w-500 {
        //min-width: toRem(590);
        //max-width: toRem(590);

        @include extension {
          min-width: calc(100% - 80px);
          max-width: 100%;
          //  overflow: auto;
        }
      }

      @include extension {
        overflow: initial;
        min-width: calc(100% - 80px);
        max-width: 100%;
        max-height: calc(100vh - 200px);
      }
    }

    .p-dialog-footer {
      margin: toRem(0) toRem(40);
      padding: toRem(25) toRem(0);
      border-top: 1px solid $border-color;
      border-bottom-right-radius: toRem(10);
      border-bottom-left-radius: toRem(10);

      &.p-dialog-footer > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &.scrollable {
      max-height: calc(100vh - 250px);
      overflow: auto;

      @include scrollbars();
    }
  }
}

// Voting Modal
.location {
  color: $base-blue;
  font-size: toRem(14);
  font-weight: $fontWeight;
  display: block;
  margin-top: toRem(8);
}

.listview-wrapper {
  // overflow-y: auto;
  // max-height: toRem(200);
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: toRem(30);
  width: 56rem;

  @include scrollbars();
}

.list-action {
  i {
    cursor: pointer;

    &.active {
      color: $base-orange;
    }
  }
}

.list-view {
  border-radius: 16px;
  background: $base-bg;
  padding: toRem(20) !important;
  margin-bottom: toRem(12);
  margin-right: toRem(8);

  .list-info {
    h2 {
      color: $textColor;
      font-size: toRem(21);
      font-weight: $font-medium;
      margin-bottom: toRem(2);
    }

    p {
      color: $base-blue;
      font-size: toRem(14);

      span {
        color: $textColor;
      }
    }
  }

  p.comment {
    color: $textColor;
    font-size: toRem(14);
    line-height: 22px;
    margin-top: toRem(20);
  }
}

.flex-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.voting-modal-wrapper {
  height: toRem(350);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
  @include scrollbars();
  .voting-members {
    position: relative;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: toRem(3) solid;

    &.space {
      margin-top: toRem(22);
      margin-bottom: toRem(8);
    }

    &.blue {
      border-color: $base-light-blue !important;
    }

    &.red {
      border-color: $base-red !important;
    }

    &.orange {
      border-color: $base-orange !important;
    }

    img {
      width: 62px;
      height: 62px;
      border-radius: 100%;
    }

    span {
      position: absolute;
      display: flex;
      width: 38px;
      height: 25px;
      padding: toRem(3) toRem(10);
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      border: 1px solid $base-white !important;
      color: $base-white;
      text-align: center;
      font-size: toRem(14);
      font-weight: $font-medium;
      bottom: -12px;

      &.blue {
        background: $base-light-blue;
      }

      &.red {
        background: $base-red;
      }

      &.orange {
        background: $base-orange;
      }
    }
  }
}

.w-565 {
  width: 565px;
}

.notification-count {
  padding: 5px;
  border-radius: 12px;
  height: 20px;
  width: 20px;
  text-align: center;
  top: -15px !important;
  right: -10px;
}

.v-base {
  vertical-align: -webkit-baseline-middle;
}

.input-error {
  border: 1px solid $base-red;
}

.text-blue-a {
  color: $base-blue;
  font-size: toRem(14);
  font-weight: $font-semibold;
  line-height: 21px;
  text-decoration-line: underline;
}

.text-p-a {
  color: $textColor;
  font-size: toRem(14);
  line-height: 21px;
}

.icon-delete {
  margin-left: toRem(30);
  cursor: pointer;
}

.p-toast-detail {
  line-height: 150%;
}

.p-menu {
  border-radius: toRem(8);
  background: $base-white;
  box-shadow: 0px 6px 10px 0px rgba(42, 41, 74, 0.12);
  width: min-content;
  min-width: toRem(140);

  ul.p-menu-list {
    line-height: 140%;
    padding: toRem(16);

    div {
      .p-menuitem {
        margin-bottom: toRem(8);
        cursor: pointer;

        a.p-menuitem-link {
          padding: 0;
          color: $textColor;
          font-size: toRem(12) !important;
          display: block;

          &:hover,
          &:focus,
          &:active {
            background: $base-white;
            text-decoration: none;
            color: $base-blue;
          }
        }
      }

      &:last-child .p-menuitem {
        margin-bottom: 0;
      }
    }
  }
}

.linked-contact {
  border: 1px solid $base-light-blue;
}

.p-dropdown-items-wrapper {
  @include scrollbars();
}

.filter-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;

  p {
    margin-top: toRem(14);
    font-size: toRem(14);
  }
}

.extension-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: center;

  p {
    margin-top: toRem(14);
    font-size: toRem(14);
  }
}

.p-toast {
  z-index: 999999999999 !important;
}

.display-none {
  display: none !important;
  z-index: 999999999999 !important;
}

.paragraph {
  line-height: 150%;
  margin-top: toRem(16);
}

.tag-label {
  color: $base-blue;
}

.extension-view-button-link {
  color: $base-orange;
  font-size: toRem(12);
  font-weight: $font-semibold;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.contacts-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  .paginator-contacts {
    color: $textColor;
    font-size: toRem(13);
  }

  .p-paginator-prev,
  .p-paginator-next {
    border-radius: toRem(6);
    border: 1px solid $border-color;
    background: $base-white;
    color: $textColor;
    text-align: center;
    font-size: toRem(14);
    height: toRem(32);
    min-width: toRem(32);
    margin: 0 toRem(4);

    &.p-highlight {
      background-color: $base-light-blue;
      color: $base-white;
      border-color: $base-light-blue;
      box-shadow: none;
    }
  }
}

.w-year {
  min-width: toRem(170);
  padding-left: 0;
  padding-right: 0;

  .p-datepicker-header {
    font-size: toRem(14);
  }
}

.listview-wrapper2 {
  overflow-y: auto;
  max-height: toRem(100);
  overflow-x: hidden;
  margin-top: toRem(30);

  @include scrollbars();
}

.vote-comment-title {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.mx-500 {
  max-width: toRem(535);
}

.p-datatable-scrollable .p-frozen-column {
  border-bottom-width: toRem(2) !important;
  // margin-top: 1px !important;
}

.whitespace-pre-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: toRem(300);
}

.extension-html-class {
  font-size: toRem(14);
}

.second-level-connection-p-tag {
  font-size: toRem(16);
  line-height: 155%;
  cursor: pointer;
}

.error {
  color: $base-red;
  font-size: toRem(14);
  margin-top: toRem(6);
}

.no-data {
  // text-align: center;
  // padding: toRem(60) 0 !important;
  // border-radius: toRem(10);
  // background: $base-white;

  img {
    max-width: 70%;
  }
}

.loading-sso {
  opacity: 0.2 !important;
}

.search-results-wrapper {
  border-radius: toRem(10);
  background: $base-white;
  box-shadow: 0px 10px 14px 0px rgba(37, 51, 87, 0.15);
  padding: toRem(30) toRem(20) 0px toRem(20);
  width: calc(100% + 40%);
  min-height: toRem(520);
  max-height: toRem(520);
  left: -20% !important;
  margin-top: toRem(50) !important;
  //margin-left: -80px;
  position: absolute;
  top: 0 !important;
  max-width: initial;

  @include media(xl, xxl, xxxl) {
    //  min-width: toRem(660);
  }

  @include media(xs, sm) {
    width: 100%;
    padding: 0 toRem(20) 0px toRem(20);
    margin-top: toRem(43) !important;
    left: 0px !important; 
    top: 2px !important;
    min-height: calc(64vh - 25px);
    max-height: toRem(520);
  }

  @include scrollbars();
  overflow-y: auto;
  overflow-x: hidden;

  .p-tabview-nav-container {
    .p-tabview-nav-content {
      .p-tabview-nav {
        li {
          margin-right: toRem(20);
          padding: 0 0;
          margin-bottom: 0;

          .p-tabview-nav-link {
            font-size: toRem(14);
            font-weight: $font-regular;
            padding: 0 0 toRem(10) 0;
          }
        }
      }
    }
  }

  .p-tabview-panels {
    padding: toRem(25) 0 0;
    max-height: 375px;

    .view-all {
      color: $base-orange;
      font-size: toRem(14);
      font-weight: $font-semibold;
      text-decoration-line: underline;
      text-align: center;
      cursor: pointer;
    }

    .search-item {
      margin-bottom: torem(12);
      cursor: pointer;

      .count {
        border-radius: toRem(100);
        background: $base-light-blue;
        width: toRem(38);
        height: toRem(38);
        color: $base-bg;
        font-size: toRem(14);
      }

      h4 {
        color: $textColor;
        font-size: toRem(16);
        font-weight: $font-medium;
        display: block;
      }

      p {
        color: $textColor;
        font-size: toRem(14);
        font-weight: $font-regular;
      }
    }
  }
}

.tool-tip-class {
  z-index: 1010 !important;
}

.h-full {
  min-height: toRem(42);
}

.not-found-extension {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 90px;
}

.p-table-no-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: toRem(600);

  &.search {
    min-height: toRem(420);
    max-height: toRem(420);
  }
  img {
    max-width: fit-content;
  }

  h3 {
    font-size: toRem(18);
    font-weight: $font-regular;
    margin-top: toRem(20);
    color: $textColor;
    opacity: 0.5;
  }
}

// AI Page css

.sub-block {
  padding: toRem(16);
  border: 1px solid $border-color;
  border-radius: toRem(8);
  margin-bottom: toRem(16);
  line-height: 170%;
}

.sub-block2 {
  padding: toRem(16);
  border-radius: toRem(8);
  margin-bottom: toRem(16);
  line-height: 170%;
}

.section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: toRem(8);

  h2 {
  }
}

.section-utility-buttons-container {
  display: flex;
  justify-items: flex-start;
  gap: 15px;

  button {
    padding: toRem(9) toRem(15) !important;
    display: flex;
    flex-wrap: nowrap;
    font-size: toRem(12) !important;
  }
}

.model-selector-button{
    height: 100%!important;
    .p-button{
      border: 1px solid $base-orange!important;
    }
}

.portfolio-wrapper{
  min-width: 70%!important;
  min-height: 68%!important;
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .portfolio-wrapper {
    min-height: 85% !important;;
    min-width: 97% !important;
  }
}

.portfolio-preview-container {
  overscroll-behavior: none;
  //max height changed with height for iframe styling using width and height
  height: calc(100vh - toRem(500));
  height: -webkit-calc(100vh - toRem(500));
  height: -moz-calc(100vh - toRem(500));
  @include media(lg) {
    height: calc(100vh - toRem(500));
    height: -webkit-calc(100vh - toRem(500));
    height: -moz-calc(100vh - toRem(500));
  }

  @include media(xl, xxl, xxxl) {
    height: calc(100vh - toRem(25));
    height: -webkit-calc(100vh - toRem(25));
    height: -moz-calc(100vh - toRem(25));
  }

  @include media(xs, sm) { // Targeting mobile screens
    padding: 0 !important;
    height: 50vh
  }
}