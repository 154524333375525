// Avatar
.p-avatar-group.rand-color {

    .p-avatar {
        background-color: $base-light-blue;
        border-color: $avatar-border;
        color: $base-white;


    }
}

.p-avatar {
    font-size: toRem(14);

    &.w-38 {
        width: toRem(38);
        height: toRem(38);
        font-weight: $fontWeight;
    }

    &.cyan,
    &.orange,
    &.grey {
        border-color: $avatar-border;
        color: $base-white;

        &.cyan {
            background-color: $base-cyan;



        }

        &.orange {
            background-color: $base-orange;
        }

        &.grey {
            background-color: grey;
        }

    }

    &.inline {
        display: inline-flex;
    }


}

.random {
    .p-avatar-group {
        .p-avatar {
            &:nth-child(2) {
                background-color: $base-orange;
            }

            &:nth-child(3) {
                background-color: $base-red;
            }

            &:nth-child(4) {
                background-color: $base-blue
            }
        }
    }

}

.p-avatar-group p-avatar+p-avatar {
    margin-left: -0.75rem !important;


}