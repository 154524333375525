// Input Switch
.p-inputswitch {
    height: toRem(22);
    width: toRem(48);

    .p-inputswitch-slider {
        background: $switch-bg;
        outline: none !important;
        box-shadow: none !important;

        &:before {
            width: toRem(18);
            height: toRem(18);
            left: toRem(3);
            margin-top: toRem(-9);
            filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.20));
        }
    }


    &:hover,
    &:focus,
    &.p-focus &:not(.p-disabled):hover {
        .p-inputswitch-slider {
            background: #BFBFBF !important;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background: $base-light-blue !important;

            &:before {
                transform: translateX(toRem(24));
            }
        }
    }
}