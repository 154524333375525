@import './../../scss/helpers/variables';
@import './../../scss/helpers/mixins';

.container {
    display: flex;
    flex-direction: row;
    position: relative;
}

.container aside {
    position: relative;
    width: toRem(0);
    transition: all 0.2s;
    -webkit-transition: all .2s;
    -moz-transition: all 0.2s;
    background: $base-white;
    box-shadow: -10px 4px 14px 0px rgba(0, 0, 0, 0.10);
    // position: -webkit-sticky;
    // position: sticky;
    // left: 0;

    @include media(xs, sm){
        display: none !important;
    }

    ul {
        opacity: 0;
        visibility: hidden;

    }

    &::after {
        content: '';
        background-color: $base-light-blue;
        height: 100%;
        width: toRem(10);
        position: absolute;
        top: 0;
        opacity: 1;
        transition: all 0.4s;
        -webkit-transition: all .4s;
        -moz-transition: all 0.4s;
        min-height: 100vh;
    }

    ul {
        opacity: 0;
        visibility: hidden;

    }

    &::after {
        content: '';
        background-color: $base-light-blue;
        height: 100%;
        width: toRem(0);
        // width: toRem(10); Commented to hide filter dropdown.
        position: absolute;
        top: 0;
        opacity: 1;
        transition: all 0.4s;
        -webkit-transition: all .4s;
        -moz-transition: all 0.4s;
    }

    @include extension {
        display: none;
    }

    .btn-drawer {
        position: absolute;
        right: toRem(-36);
        z-index: 100;
        top: toRem(12);
        cursor: pointer;
        animation: rotateAnimation 0.3s;
        transform: rotate(-180deg);

        .icon-drawer {
            border: 2px solid $base-white;
            border-radius: 50%;
            font-size: toRem(32);
            box-shadow: 0px 4px 8px 0px rgba(71, 216, 191, 0.20);
            animation: rotateReverseAnimation 0.2s;
        }
    }

    &.expanding {
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        opacity: 1;

        //visibility: visible;
        ul {
            opacity: 1;
            visibility: visible;
        }

        &::after {
            opacity: 0;
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
        }

        .btn-drawer {
            right: toRem(-24);
            rotate: 180deg;
            animation: rotateReverseAnimation 0.3s;

            .icon-drawer {
                border-color: transparent;
            }
        }
    }

    @keyframes rotateAnimation {
        0% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    @keyframes rotateReverseAnimation {
        0% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    .filter-head {
        background: $base-bg;
        box-shadow: -10px 4px 14px 0px rgba(0, 0, 0, 0.10);
        border-bottom: 1px solid $border-color;
        padding: toRem(30) toRem(20) toRem(17);
        display: none;

        h2 {
            color: $textColor;
            font-size: toRem(21);
            font-weight: $font-semibold;
        }

        .lbl-clear {
            color: $base-orange;
            font-size: toRem(12);
            font-weight: $font-semibold;
            text-decoration-line: underline;
            float: right;
            cursor: pointer;
            margin-top: toRem(7);
        }
    }

    .vertical-nav {
        display: none;
    }
}

.content-wrapper {
    //width: 100%;
    height: 100%;
    width: 100vw;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;

    &.expandingContent {
        width: calc(100vw - toRem(320));
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
    }

    .content {
        padding: toRem(0) toRem(36) toRem(20) toRem(34);
        @include media(xs, sm) {
            padding: toRem(0) toRem(15) toRem(20) toRem(15);
        }
    }

    @include extension {
        display: none;
    }

    @include scrollbars();
}

.container aside.expanding {
    background: $base-white;
    box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.20);
    width: toRem(320);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    position: relative;
    z-index: 12;

    .filter-head {
        background: $base-bg;
        box-shadow: 0px 4px 18px 0px rgba(0, 36, 56, 0.08);
        display: block;
    }

    // Vertical Menu 

    .menu {
        position: relative;
        min-height: calc(100vh - toRem(75));
    }

    .vertical-nav {

        height: auto;
        list-style: none;
        width: 100%;
        padding: 0 toRem(20);
        display: block;
        min-width: toRem(320);
        background-color: $base-white;
        height: calc(100vh - toRem(160));
        overflow-y: auto;
        @include scrollbars();

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .vertical-nav>li {
        // position: relative;
        margin: 0;

        border: none;
        text-align: left;
        display: inline-block;
        clear: both;
        width: 100%;
        border-bottom: 1px solid $border-color;
        background-color: $base-white;

        &.last {
            border: none;
        }

        &.filter-buttons {
            border: none;
            text-align: center;
            padding: 0;
            // position: absolute;
            bottom: 10px;
            padding-top: 7px;
            width: 100%;
            left: 0;
            background-color: transparent;

            // position: absolute;
            // bottom: -20px;
            ul {
                display: flex; // Use flexbox layout
                list-style-type: none; // Remove bullet points from the list
                padding: 0;
                justify-content: space-around;
                width: 100%;
                padding-top: 8px;
                padding-bottom: 5px;

                li {
                    &.btn-save {
                        a {
                            display: flex;
                            padding: 10px 20px;
                            align-items: center;
                            border-radius: 6px;
                            background: var(--secondary, #FF6C39);
                            color: #fff;
                            font-size: toRem(15);
                            font-family: Poppins;
                            font-style: normal;
                            font-weight: $font-medium;
                            line-height: normal;
                            outline: none;
                            box-shadow: none;
                            border: none;
                            min-width: toRem(165);
                            min-height: toRem(20);
                            align-items: center;
                            justify-content: center;

                            &:hover {
                                color: $base-white !important;
                                text-decoration: none;
                            }
                        }
                    }

                    &.search-button {
                        a {
                            display: flex;
                            padding: 10px 10px;
                            align-items: center;
                            border-radius: 6px;
                            color: var(--secondary, #FF6C39);
                            font-size: toRem(15);
                            font-weight: $font-medium;
                            font-family: Poppins;
                            font-style: normal;
                            line-height: normal;
                            outline: none;
                            box-shadow: none;
                            border: none;
                            min-width: toRem(90);
                            min-height: toRem(20);
                            align-items: center;
                            justify-content: center;
                            text-decoration: underline;

                            &:hover {
                                text-decoration: underline;
                                //  font-weight: bold;
                            }



                        }
                    }
                }
            }

        }



        &.active>ul {
            display: block;
        }
    }

    .vertical-nav>li:hover a,
    .vertical-nav>li.active a {
        color: $base-orange;
        text-decoration: none;
    }

    .vertical-nav li>a {
        color: $textColor;
        font-size: toRem(16);
        font-weight: $font-semibold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        // padding: toRem(30) 0;
        min-height: toRem(80);

        .label-a {
            display: flex;
            align-items: center;
            justify-content: center;

            .count {
                border-radius: 100px;
                background: $base-orange;
                color: $base-white;
                font-size: 12px;
                font-weight: 600;
                width: 24px;
                height: 24px;
                margin-left: 8px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .vertical-nav>li.current a {
        color: $base-orange;
    }

    .sub-menu {
        z-index: 9800;
        min-width: toRem(400);
        max-width: toRem(400);
        border-radius: 0px toRem(8) toRem(8) 0px;
        box-shadow: 10px 4px 14px 0px #00000026;
        border-left: 1px solid $border-color;
        left: 100%;
        width: 100%;
        white-space: nowrap;
        z-index: 10;
        background-color: $base-white;
        display: flex;
        padding: 0;
        position: absolute;
        margin-top: toRem(78);

        &.scroll-enable {
            max-height: calc(100vh - 160px);
            max-height: -webkit-calc(100vh - toRem(160px));
            max-height: -moz-calc(100vh - toRem(160px));
            overflow-y: auto;

            @include scrollbars();
        }

        &.sub-menu>li {
            padding: toRem(20);

            .clear-btn {
                color: $base-orange;
                font-size: toRem(12);
                font-weight: $font-semibold;
                text-decoration-line: underline !important;
                float: right;
                cursor: pointer;
                margin-bottom: toRem(10);
            }
        }
    }

    .f-i-wrapper {
        &.keywords {
            padding: toRem(20) toRem(10);
            border-radius: toRem(8);
            border: 1px solid $border-color;
        }
    }

}

.fte-data,
.growth {
    font-size: toRem(16);
    font-weight: $font-medium;
    line-height: toRem(25);

    strong {
        font-size: toRem(26);
    }

    &.growth {
        color: $base-light-blue;
        margin-left: toRem(8);
    }
}

.label-tag {
    margin-bottom: toRem(24);
    font-size: toRem(16);
    font-weight: $fontWeight;

    strong {
        margin-bottom: toRem(10);
        font-weight: $font-semibold;
        display: block;
    }

    p {
        margin-bottom: toRem(10);
        line-height: 150%;

        &.investor-label {
            cursor: pointer;
            text-decoration: underline;

            @include extension {

                cursor: default;
                text-decoration: none;

            }
        }




    }
}