// Radio Boxes
.p-radiobutton-label {
    margin-bottom: 0;
}

.p-radiobutton {
    vertical-align: middle;
    width: toRem(16);
    height: toRem(16);

    .p-radiobutton-box {
        width: toRem(16);
        height: toRem(16);
        border: 1px solid $border-color;
        box-shadow: none;

        .p-radiobutton-icon {
            background: $base-white;
            border-color: $base-light-blue;
        }

        &.p-highlight,
        &.p-highlight:not(.p-disabled):hover,
        &:not(.p-disabled).p-focus {
            border-color: $base-light-blue;
            background: $base-light-blue;
            box-shadow: none;

            .p-radiobutton-icon {
                width: toRem(6);
                height: toRem(6);
            }
        }
    }

}

//Radiobox Styling 
input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: $base-white;
    /* Not removed via appearance */
    margin: 0;
    width: 16px;
    height: 16px;
    border: 1px solid $border-color;
    border-radius: 50%;

    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $base-white;
}

input[type="radio"]:checked::before {}

input[type="radio"]:checked {
    background-color: $base-light-blue;
    border-color: $base-light-blue;

}