.padd-0 {
    padding: 0 !important;
}

// Icon Set 
[class^="icon-"] {
    color: inherit;
}

.bdr-top {
    border-top: 1px solid $border-color;
}

.mgr-0 {
    margin: 0 !important;
}

.m-rl-2 {
    margin: 0 toRem(8) !important;
}

.mg-t-10 {
    margin-top: toRem(10) !important;
}

.mg-t-20 {
    margin-top: toRem(16);
}

.mg-t-16 {
    margin-top: toRem(20);
}

.mg-b-16 {
    margin-bottom: toRem(16) !important;
}

.mg-b-12 {
    margin-bottom: toRem(12) !important;
}

.mg-t-20 {
    margin-top: toRem(20) !important;
}

.mg-tb-12 {
    margin: toRem(12) 0 !important;
}

.mg-tb-30 {
    margin: toRem(30) 0;
}

.mg-tb-20 {
    margin: toRem(20) 0;
}

.small {
    font-size: toRem(10);
}

.ml-40 {
    margin-left: toRem(40);
}

.ml-15 {
    margin-left: toRem(15);
}

.mb-20 {
    margin-bottom: toRem(20) !important;
}

.mb-10 {
    margin-bottom: toRem(10) !important;
}

.mg-b-20 {
    margin-bottom: toRem(20);
}

.mg-b-30 {
    margin-bottom: toRem(30) !important;
}

.mg-b-10 {
    margin-bottom: toRem(10) !important;
}


.padd-tb-20 {
    padding-top: toRem(20);
    padding-bottom: toRem(20);
}

.padd-tb-12 {
    padding-top: toRem(12);
    padding-bottom: toRem(12);
}

.padd-t-16 {
    padding-top: toRem(16);
}

.opacity-50 {
    opacity: .5;
    pointer-events: none;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.flex-w-auto {
    flex: 0 0 auto;
}

.position-relative {
    position: relative;
}

.f-18 {
    font-size: toRem(18);
}

.f-16 {
    font-size: toRem(16) !important;
}

.f-34 {
    font-size: toRem(34);
}

.f-25 {
    font-size: toRem(25);
}

.f-45 {
    font-size: toRem(45);
}

.f-medium {
    font-weight: $font-medium;
}

.py-18 {
    padding: toRem(18) 0;
}

.mt-20 {
    margin-top: toRem(20) !important;
}