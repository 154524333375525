// Accordian Styles
.p-accordion {
    .p-accordion-tab {
        box-shadow: 0px 5px 5px 0px rgba(32, 52, 104, 0.06);
        background: $base-white;
        border-radius: toRem(6);
        margin-bottom: toRem(10) !important;

        .p-accordion-header {
            .pi-chevron-right {
                rotate: 90deg;
            }

            .pi-chevron-down {
                rotate: 180deg;
            }

            .p-accordion-header-link {
                background: $base-white;
                flex-direction: row-reverse;
                justify-content: space-between;
                outline: none;
                font-weight: $font-semibold;
                border-bottom-right-radius: toRem(6);
                border-bottom-left-radius: toRem(6);
                padding: toRem(15) toRem(20) toRem(15) toRem(20);
                text-decoration: none !important;
                border: none !important;
                line-height: 150%;
            }

            .title {
                color: $textColor;
                font-size: toRem(16);
                opacity: 0.800000011920929;
                display: block;
            }

            .date {
                color: $base-blue;
                font-size: toRem(14);
                display: block;
                font-weight: $fontWeight;
            }
        }


        .p-accordion-content {

            border-bottom-right-radius: toRem(6);
            border-bottom-left-radius: toRem(6);
            border: none;
            padding: toRem(0) toRem(20) toRem(15) toRem(20);

            .accordian-content {
                padding: toRem(15) toRem(20);
                background-color: $base-bg;
                display: block;
                border-radius: toRem(6);
                font-size: toRem(14);

                .label-small {
                    line-height: 150%;
                    color: $textColor;

                    strong {
                        font-size: toRem(12);
                        display: block;
                    }
                }
            }

        }


    }

    &.pic-accordian {
        .p-accordion-header {


            .p-accordion-header-link {
                font-size: toRem(14);
                font-weight: $fontWeight
            }

            .amount {
                color: $textColor;
                font-size: toRem(26);
                opacity: 0.800000011920929;
                display: block;
                font-weight: $font-semibold;
            }

            .date {
                margin-top: toRem(8);
            }

        }
    }
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: $base-white;
    box-shadow: none;
    outline: none;

}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: $base-white;
    box-shadow: none;
    outline: none;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
}