// Base Colors 
@import './variables';

$textColor: $textColor;


// Base Font 
$fontFamily: 'Poppins',
sans-serif;
$fontWeight:400;
$fontSize: toRem(16);

// Gutters
$gutter: 0 toRem(20)