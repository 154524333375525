// Text Boxes
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  color: $textColor;
  font-size: toRem(14) !important;
  font-family: $fontFamily;
}

.p-autocomplete-panel {
  // top: initial !important;
  // left: initial !important;
  @include scrollbars();

  @include extension {
    width: 100%;
  }
}

.p-inputtext {
  border-radius: toRem(6);
  border: 1px solid $border-color;
  background: $base-white;
  padding: toRem(11) toRem(20);
  color: $textColor;
  font-size: toRem(14) !important;

  &:hover,
  &:focus,
  &:active,
  &:enabled:hover {
    border-color: $base-light-blue !important;
    box-shadow: none !important;
  }
}

// Dropdown
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $base-blue;
  background: $base-bg;
}

.p-inputwrapper {
  .p-dropdown {
    border-radius: toRem(6);
    border: 1px solid $border-color;
    background: $base-white;
    padding: toRem(0) 0;
    color: $textColor;
    font-size: toRem(14) !important;
    max-width: 100%;

    &.no-padd {
      padding: 0;
    }

    .p-dropdown-trigger {
      width: toRem(40);
    }

    .p-dropdown-label {
      padding: toRem(15) toRem(0) toRem(14) toRem(20);
    }

    &.p-dropdown:not(.p-disabled).p-focus {
      box-shadow: none;
    }

    .p-dropdown-label.p-placeholder {
      color: $textColor;
      font-size: toRem(12);
    }

    &:not(.p-disabled):hover {
      border-color: $base-light-blue !important;
      box-shadow: none !important;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    color: $textColor;
    font-size: toRem(14) !important;
  }
}

.p-calendar-w-btn.calender {
  .p-datepicker-trigger {
    border-color: $border-color;
    border-left: none;
  }

  .p-inputtext {
    border-right: none;
    padding-right: 0;
    padding-left: toRem(16);
  }

  &.p-focus,
  &.p-hover,
  &:hover,
  &:focus,
  &:active {
    .p-datepicker-trigger,
    .p-inputtext {
      border-color: $base-light-blue;
    }
  }

  .p-button {
    background: transparent;
    color: $textColor;
    padding: 0;
    border-color: $border-color;
    border-left: none;

    &.p-button:enabled:hover,
    &.p-button:enabled:focus {
      background: transparent;
      color: $base-orange;
      border-color: $base-light-blue;
      box-shadow: none;
    }
  }
}

.p-datepicker .p-yearpicker,
.p-datepicker .p-monthpicker {
  display: flex;
  flex-wrap: wrap;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: $base-blue;
  background-color: $base-bg;
}

// Auto Complete

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0;
}
.p-autocomplete {
  min-width: 100%;
  display: flex;

  .p-autocomplete-multiple-container {
    width: 100%;
    display: flex;
    gap: toRem(16);
  }
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  border-color: $base-light-blue !important;
  box-shadow: none !important;
}

//Input Number
.p-inputnumber {
  width: 100%;

  .p-inputnumber-input {
    width: calc(100% - 64px);
  }

  .btn-input .p-button-decrement,
  .btn-input .p-button-increment {
    width: 32px;
  }

  .p-button.p-button {
    color: $textColor;
  }
}

// Date Picker
.p-datepicker table td,
.p-datepicker table th {
  font-size: toRem(14);
  text-align: center;
}

.p-datepicker table td > span.p-highlight {
  color: $base-blue;
  background: $base-bg;
}

.p-datepicker table td {
  padding: 0.2rem;
}

.p-datepicker table td > span.p-highlight {
  color: $base-blue;
  background: $base-bg;
}

// Sai jaga place karni hai
.p-tabview .p-datatable .p-paginator .p-paginator-current {
  height: fit-content;
}

// Passowrd Component
.p-password {
  .p-inputtext {
    width: 100%;
  }
}
