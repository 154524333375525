// Tags
.tags-wrap {
  display: flex;
  flex-wrap: wrap;

  .tags {
    margin-right: toRem(10);
    margin-bottom: toRem(10);

    &:last-child {
      margin-right: 0;
    }
  }

  @include extension {
    &.max-lines {
      max-height: 135px;
      overflow: hidden;
    }
  }
}

.tags-wrap-modal {
  display: flex;
  flex-wrap: wrap;
  gap: toRem(10);

  .tags {
    margin-right: toRem(10);

    &:last-child {
      margin-right: 0;
    }
  }
}

.tags-wrap-labels {
  display: flex;
  flex-wrap: wrap;
  // overflow-y: hidden;
  gap: toRem(10);

  //     .tags {
  //         // margin-right: toRem(10);

  //         // &:last-child {
  //  //     margin-right: 0;
  //  // }
  //     }

  // @include media(xl, xxl, xxxl) {
  //     max-height: toRem(38);
  // }
}

.buttons-wrap-labels {
  display: flex;
  align-items: center;

  button {
    padding: toRem(9) toRem(15) !important;
    display: flex;
    flex-wrap: nowrap;
    font-size: toRem(12) !important;
  }
}

.tags,
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  border-radius: toRem(6);
  border: 1px solid $base-blue;
  background: $base-white;
  display: flex;
  padding: toRem(9) toRem(15);
  align-items: center;
  color: $base-blue;
  font-size: toRem(12);
  font-weight: $font-medium;
  cursor: pointer;
  margin: 0;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;

  &.inline {
    display: inline-flex;
  }

  i,
  .p-autocomplete-token-icon.pi-times-circle {
    margin-left: toRem(6);
    font-size: toRem(11);

    &.pi-times-circle {
      &:before {
        content: "\e90b";
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus,
  &:active,
  .active {
    background: $base-blue;
    color: $base-white;
    border-color: $base-blue;
    .attachment-text{
      color: white;
    }
  }

  &.no-hover {
    &:hover,
    &:focus,
    &:active,
    .active {
      border: 1px solid $base-blue;
      background: $base-white;
      color: $base-blue;
      .attachment-text{
        color: black;
      }
    }
  }

  &.filled {
    background: $base-blue;
    color: $base-white;
    border-color: $base-blue;

    &:hover,
    &:focus,
    &:active {
      background: $base-blue;
      color: $base-white;
      border-color: $base-blue;
    }
  }
}

// Start Tags

.star-tag {
  border: 1px solid;
  border-radius: 26px;
  padding: 6px;
  display: flex;
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  min-width: toRem(60);

  &.m-width {
    min-width: toRem(70);
    max-width: toRem(70);
  }
  .star-circle {
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $base-white;
    font-size: toRem(12);
    font-weight: $font-bold;
    margin-right: toRem(6);

    .pi {
      color: $base-white;
      font-size: 12px;

      &.pi-user {
        font-size: toRem(16);
        color: $base-blue;
      }
    }
  }

  .star-circle {
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $base-white;
    font-size: toRem(12);
    font-weight: $font-bold;
    margin-right: toRem(6);

    .pi {
      color: $base-white;
      font-size: 12px;

      &.pi-user {
        font-size: toRem(16);
        color: $base-blue;
      }
    }
  }

  .star-value {
    text-align: center;
    font-size: toRem(12);
    font-weight: $font-medium;

    &.f-14 {
      font-size: toRem(14);
    }
  }

  &.txt-blue {
    border-color: $border-color;
    background: $base-bg;

    .star-circle i {
      color: $base-blue;
    }

    .star-value {
      color: $base-blue;
    }
  }

  &.blue {
    border-color: $base-cyan;
    background: rgba(71, 216, 191, 0.15);

    .star-circle {
      background: $base-cyan;
    }

    .star-value {
      color: $base-cyan;
    }
  }

  &.orange {
    border-color: $base-orange;
    background: rgba(253, 204, 186, 0.3);

    .star-circle {
      background: $base-orange;
    }

    .star-value {
      color: $base-orange;
    }
  }

  &.red {
    border-color: $base-red;
    background: rgba(243, 186, 186, 0.3);

    .star-circle {
      background: $base-red;
    }

    .star-value {
      color: $base-red;
    }
  }

  &.grey {
    border-color: $base-grey;
    background: rgba(48, 48, 48, 0.3);

    .star-circle {
      background: $base-grey;
    }

    .star-value {
      color: $base-grey;
    }
  }
}

// P-Tags  Styles
.tag-blue,
.tag-light-blue {
  border-radius: toRem(6);
  box-shadow: 0px 6px 7px 0px rgba(20, 122, 150, 0.15);

  padding: toRem(6) toRem(10);

  .p-tag-value {
    color: $base-white;
    font-size: toRem(12);
    font-weight: $font-medium;
  }

  &.tag-blue {
    background: $base-blue;
  }

  &.tag-light-blue {
    background: $base-light-blue;
  }
}
