// Mixins for Responsive
$extensionMin: 550px;
$extensionMax: 570px;
$xs: 200px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1600px;

@mixin xs {
    @media only screen and (min-width: $xs) and (max-width: $sm) {
        @content;
    }
}

@mixin sm {
    @media only screen and (min-width: $sm) and (max-width: $md) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: $md) and (max-width: $lg){
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: $lg) and (max-width: $xl) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: $xl) {
        @content;
    }
}

@mixin xxl {
    @media only screen and (min-width: $xxl) {
        @content;
    }
}

@mixin xxxl {
    @media only screen and (min-width: $xxxl) {
        @content;
    }
}


@mixin extension {
    @media only screen and (min-width: $extensionMin) and (max-width: $extensionMax) {
        @content;
    }
}

@mixin media($keys...) {
    @each $key in $keys {
        @if ($key==xs) {
            @include xs {
                @content
            }
        }

        @else if ($key==sm) {
            @include sm {
                @content
            }
        }

        @else if ($key==md) {
            @include md {
                @content
            }
        }

        @else if ($key==lg) {
            @include lg {
                @content
            }
        }

        @else if ($key==xl) {
            @include xl {
                @content
            }
        }

        @else if ($key==xxl) {
            @include xxl {
                @content
            }
        }

        @else if ($key==xxl) {
            @include xxl {
                @content
            }
        }

        @else if ($key==extension) {
            @include extension {
                @content
            }
        }
    }
}


@mixin scrollbars() {

    // For Google Chrome
    &::-webkit-scrollbar {
        width: toRem(8);
        height: toRem(8);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
        -webkit-box-shadow: none;
        border-radius: toRem(4)
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: $scrollbar-bg;
        border-radius: toRem(4)
    }

    // For Internet Explorer
    & {
        scrollbar-face-color: $scrollbar-thumb;
        scrollbar-track-color: $scrollbar-bg;
    }

    // &::-webkit-scrollbar-track {
    //     -webkit-box-shadow: none;
    //     background-color: $scrollbar-bg;
    //     border-radius: toRem(4)
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: $scrollbar-thumb;
    //     -webkit-box-shadow: none;
    //     border-radius: toRem(4)
    // }
}