.btn-download {
    display: flex;
    padding: toRem(10) toRem(20);
    align-items: center;
    border-radius: toRem(6);
    background: $base-orange;
    color: $base-white;
    font-size: toRem(14);

    font-weight: $font-medium;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        /* Add other styles for disabled state */
    }
}

.btn-vote {
    display: inline-flex;
    padding: toRem(7) toRem(12);
    justify-content: center;
    align-items: center;
    border-radius: toRem(4);
    border: 1px solid $base-blue;
    color: $base-blue;
    text-align: center;
    font-size: toRem(12);
    font-weight: $font-medium;
    background: transparent;
    cursor: pointer;

    .check-wrap {
        width: 16px;
        height: 16px;
        border: 1px solid $base-blue;
        border-radius: toRem(4);
        margin-right: toRem(8);
        display: flex;
        align-items: center;
        justify-content: center;

        .pi {
            font-size: 8px;
        }
    }

    &:hover,
    &:active,
    &:focus {
        background: $base-light-blue;
        color: $base-white;
        border-color: $base-light-blue;

        .check-wrap {
            border: 1px solid $base-white;
        }
    }
}

// Input Numbers Fields

.btn-input {
    border-radius: toRem(6);
    border: 1px solid $border-color;
    background: $base-white;
    // padding: toRem(11) toRem(20);
    color: $textColor;
    font-size: toRem(14) !important;



    .p-inputnumber-input {
        border: none;
        box-shadow: none;
        padding: 0;
        text-align: center;
        min-height: toRem(45);
    }


    &:hover,
    &:focus,
    &:active,
    &:enabled:hover {
        border-color: $base-light-blue !important;
        box-shadow: none !important;
    }

    .p-button-decrement,
    .p-button-increment {
        background: none;
        border: none;
        color: $textColor;
        padding: toRem(14) 0;

        .pi {
            font-size: toRem(14);
        }


        &:enabled:active,
        &:enabled:hover {
            background-color: transparent;
            border: none;
            color: $base-orange;
        }
    }
}


//Button Set 

.p-buttonset {
    display: inline-flex;

    .p-button {
        color: $textColor;
        font-size: toRem(14) !important;
        padding: toRem(10) toRem(0) !important;
        background-color: transparent;
        font-weight: $fontWeight;
        border: none;
        border-radius: toRem(6);
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        background: $base-white;
        min-width: toRem(60);
        min-width: toRem(60);


        .p-button-label {
            font-weight: $fontWeight;
        }

        &:first-of-type {
            border-left: 1px solid $border-color;
        }

        &:last-of-type {
            border-right: 1px solid $border-color;
        }

        &:hover,
        &:focus,
        &:active,
        &:enabled:hover,
        &.p-highlight,
        &.p-highlight:hover {
            border-color: $base-orange;
            background: $base-orange;
            box-shadow: none;
            color: $base-white;
        }
    }

    &.black {
        position: relative;

        .p-button {
            // color: $textColor;
            //background-color: transparent;
            //  font-weight: $font-medium;
            //  border-top: 1px solid $border-color;
            //  border-bottom: 1px solid $border-color;
            //  background: $base-white;

            // .p-button-label {
            //     font-weight: $fontWeight;
            // }

            // &:first-of-type {
            //     border-left: 1px solid $border-color;
            // }

            // &:last-of-type {
            //     border-right: 1px solid $border-color;
            // }

            // &:hover,
            // &:focus,
            // &:active,
            // &:enabled:hover {
            //     //   border-color: $textColor;
            //     //   background: $textColor;
            //     box-shadow: none;
            //     color: $base-white;
            // }
        }

        &.show-lines {

            &:before,
            &:after {
                content: "";
                border-left: 1px solid $border-color;
                position: absolute;
                height: toRem(20);
                left: calc(50% - toRem(-1))
            }

            &::before {
                bottom: toRem(36);
            }

            &::after {
                top: toRem(36);

            }
        }
    }
}

// Voting Tabs 
.vote-tab-buttons {
    display: inline-flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border-radius: toRem(6);
    border: 1px solid $base-orange;
    color: $base-orange;
    font-size: toRem(16);
    font-weight: $font-medium;
    text-decoration: none !important;

    &:first-child {
        margin-right: toRem(8);
    }

    &:nth-child(1n) {
        margin-right: toRem(8);
    }

    &.active-ic-vote-tab,
    &:hover {
        background: $base-orange;
        border-color: $base-orange;
        color: $base-white;
    }
}

.button-group {
    display: flex;
}

.button {
    cursor: pointer;
    display: block;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s ease;
}

/* Style for the selected button */
.button input[type="radio"]:checked+.button {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

// Start Button
.stars-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: toRem(30);

    button {
        margin-right: toRem(12);

        &:last-child {
            margin-right: 0;
        }
    }
}

.btn-star {
    background: none;
    border: none;
    padding: 0;
    position: relative;
    cursor: pointer;

    svg path {
        stroke: $base-light-blue;
    }

    &:disabled {
        opacity: .6;
        pointer-events: none;
    }

    .star-value {
        text-align: center;
        font-size: toRem(14);
        font-weight: $font-semibold;
        color: $base-light-blue;
        //position: absolute;
        font-family: $fontFamily;
        width: 100%;
        border-radius: 50%;
        width: toRem(42);
        height: toRem(42);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: $base-light-blue;
        color: $textColor;
        transition: all 0.2s;
        opacity: .65;
    }

    &:hover,
    &:focus,
    &:active,
    &.opacity-full-selected {
        .star-value {
            background: $base-light-blue;
            color: $base-white;
            transition: all 0.2s;
            opacity: 1
        }

    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        .star-value {
            border-color: $base-red;
        }



        &:hover,
        &:focus,
        &:active,
        &.opacity-full-selected {
            .star-value {
                background: $base-red;
            }

        }
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
        .star-value {
            border-color: $base-yellow;
        }



        &:hover,
        &:focus,
        &:active,
        &.opacity-full-selected {
            .star-value {
                background: $base-yellow;
            }

        }
    }
}

// Buttons 

.p-button,
.button-dark {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $base-orange;
    text-align: center;
    font-size: toRem(14) !important;
    font-weight: $font-medium;
    outline: none;
    box-shadow: none;
    margin: 0;

    &:last-child {
        margin-right: 0 !important;
    }

    &.p-button {
        padding: toRem(10) toRem(20);
        color: $base-orange;
        background: $base-white;
        border-color: $base-orange;

        &:hover,
        &:focus,
        &:active {
            box-shadow: none !important;

            &:hover,
            &:focus,
            &:active,
            &.p-highlight {
                color: darken($base-orange, 15%);
                border-color: darken($base-orange, 15%);
                background: $base-white;


            }
        }

        &.p-highlight {

            &:hover,
            &:focus,
            &:active,
            &.p-highlight {
                color: $base-white;
                border-color: $base-orange;
                background: $base-orange;


            }
        }

    }

    &.button-dark {
        padding: toRem(10) toRem(30);
        color: $base-white;
        background: $base-orange;
        border-color: $base-orange;

        &:hover,
        &:focus,
        &:active {
            color: darken($base-orange, 15%);
            border-color: darken($base-orange, 15%);
            background: $base-white;
        }
    }
}

.p-buttonset .p-button:first-of-type {
    border-left-color: $base-orange;
}

.btn-primary,
.btn-secondary {
    border-radius: toRem(6);
    background: $base-orange;
    display: flex;
    padding: toRem(10) toRem(20);
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none !important;
    box-shadow: none !important;
    width: 100%;
    font-weight: $font-medium;
    font-size: toRem(14);
    cursor: pointer;

    &.w-auto {
        width: fit-content;
    }

    i {
        margin-right: toRem(8);
    }

    .p-button-label {
        font-weight: $font-medium;
    }

    &.btn-primary,
    &.btn-primary:hover,
    &.btn-primary:focus,
    &.btn-primary:active {
        background: $base-orange;
        color: $base-white;
        border-color: $base-orange;
        &:not(:hover){
            background: $base-orange;
            color: $base-white;
            border-color: $base-orange;
        }
        
    }

    &.btn-secondary,
    &.btn-secondary:hover,
    &.btn-secondary:focus,
    &.btn-secondary:active {
        color: $base-orange;
        background: $base-white;
        border-color: $base-orange;
    }
}

// Buttons Accept/Decline

.btn-accept,
.btn-decline {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    outline: none;
    box-shadow: none;
    margin: 0;
    padding: toRem(8) toRem(10);

    @include extension {
        margin: toRem(20) 0 !important;
    }

    .p-button-label {
        font-size: toRem(12) !important;
        font-weight: $font-medium;
    }

    &.btn-accept {
        color: $base-white;
        background: $base-light-blue;
        border-color: $base-light-blue;

        &:hover,
        &:focus,
        &:active {
            color: $base-white;
            border-color: darken($base-light-blue, 10%);
            background: darken($base-light-blue, 10%);


        }
    }

    &.btn-decline {

        color: $base-red;
        background: $base-white;
        border-color: $base-red;

        &:hover,
        &:focus,
        &:active {
            color: darken($base-red, 10%);
            border-color: darken($base-red, 10%);
            background: $base-white;
        }
    }
}


.btn-sec {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    box-shadow: none;
    margin: 0;
    padding: toRem(10) toRem(20);
    box-shadow: none !important;

    .p-button-label {
        font-size: toRem(14) !important;
        font-weight: $font-medium;
    }

    &.btn-sec {
        color: $base-orange;
        background: $base-white;
        border-color: $base-orange;

        &:hover,
        &:focus,
        &:active {
            color: darken($base-orange, 10%);
            border-color: darken($base-orange, 10%);
            background: $base-white;
        }
    }
}